#main-footer {
  width: 100%;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  background-color: #394114;
  z-index: 0;

  @media (max-width: 768px) {
    padding: 40px 0 100px 0;
    height: unset;
  }

  .container {
    height: 100%;
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;

    @media (max-width: 768px) {
      padding: 0 10px;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .left {
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 768px) {
        margin-bottom: 50px;
      }

      .phone {
        font-style: normal;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
        }

        img {
          max-width: 40px;
          margin-right: 10px;
        }

        a {
          color: white;
          font-family: "Unna", sans-serif;
          font-size: 42px;
          font-weight: bold;
          transition: opacity 350ms ease;

          @media (max-width: 768px) {
            font-size: 32px;
          }

          &:hover {
            opacity: .6;
          }
        }
      }

      .address-wrapper {
        display: flex;

        @media (max-width: 768px) {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        
        img {
          width: 18px;
          height: auto;
          align-self: center;
          margin-right: 10px;
        }
      }

      .address {
        color: white;
        font-style: normal;
        line-height: 1.2;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo-wrapper {
        max-width: 200px;

        @media (max-width: 768px) {
          max-width: 160px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
