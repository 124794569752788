/** @import: Vendor. */
@import "../vendor/normalize/normalize.css";
@import "../vendor/fancybox/jquery.fancybox.min.css";
@import "../vendor/bootstrap/bootstrap-grid.min.css";
@import "../vendor/owl/owl.carousel.min.css";

/** @import: Main. */
@import "reset";
@import "vars";
@import "fonts";

html {
  scroll-behavior: smooth;
}

/** Body. */
body {
  font-size: 16px;
  font-family: "Trebuchet MS", sans-serif;

  .title {
    font-size: 45px;
    font-weight: 800;
    font-family: "Unna", sans-serif;

    @media (max-width: 768px) {
      font-size: 32px;
      text-align: center;
    }
  }

  &::before {
    content: "";
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: 0.6;
      pointer-events: initial;
    }
  }

  .modal-window {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.45);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &:target {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }

    & > div {
      width: 90vw;
      max-width: 1000px;
      max-height: 90vh;
      overflow: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 2em;
      background: white;
    }

    p {
      line-height: 1.3;
    }
  }

  .modal-close {
    color: black;
    font-weight: bold;
    line-height: 50px;
    font-size: 24px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  .cookieConsentContainer {
    z-index: 100;
    width: 370px;
    min-height: 20px;
    box-sizing: border-box;
    padding: 30px 30px 30px 30px;
    background: #232323;
    overflow: hidden;
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;

    .cookieTitle {
      a {
        color: #fff;
        font-size: 22px;
        line-height: 20px;
        display: block;
      }
    }

    .cookieDesc {
      p {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 13px;
        line-height: 20px;
        display: block;
        margin-top: 10px;
      }

      a {
        color: #fff;
        text-decoration: underline !important;
      }
    }

    .cookieButton {
      a {
        display: inline-block;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        margin-top: 14px;
        background-color: #000;
        box-sizing: border-box;
        padding: 15px 24px;
        text-align: center;
        transition: background-color 0.3s ease;

        &:hover {
          cursor: pointer;
          background: #1a9aab;
        }
      }
    }

    @media (max-width: 900px) {
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}

/** Layout. */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }

  @media screen and (device-aspect-ratio: 40/71) {
    padding: 0 15px;
  }
}

/** @import: Globals. */
@import "globals/footer";

/** @import: Elements. */
@import "elements/progress-screen";

/** @import: Modals. */
@import "modals/general";
@import "modals/cta";
@import "modals/success";
@import "modals/whatsapp";

/** @import: Sections. */
// @import "sections/{{section_name}}";

/** @import: Pages. */
@import "pages/home";

/** @import: Pages [Mobile]. */
// @import "pages/mobile/{{page_name}}";
