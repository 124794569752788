.modal-success {
  background-color: fade-out(black, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    background-color: white;
    position: relative;
    width: 700px;
    padding: 70px 0;

    /** Mobile. */
    @media (max-width: 768px) {
      width: 90vw;
    }

    &-description {
      text-align: center;
      padding: 0 30px;

      /** Mobile. */
      @media (max-width: 767px) {
        width: 100%;
        padding: 0 25px;
      }

      p {
        color: #808080;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.7;

        /** Mobile. */
        @media (max-width: 768px) {
          br {
            display: none;
          }
        }

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      /** Mobile. */
      @media (max-width: 768px) {
        flex-direction: column;
      }

      button {
        &:not(:last-child) {
          margin-right: 15px;

          /** Mobile. */
          @media (max-width: 768px) {
            margin: 0 0 35px;
          }
        }
      }

      a {
        background-color: #e37e21;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Arial";
        color: white;
        position: relative;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        width: 290px;
        line-height: 1.4;
        height: 50px;
        text-align: center;
        transition: box-shadow 350ms ease, background-position 750ms ease;

        &:hover {
          box-shadow: 0px 8px 12px 0px rgba(19, 27, 46, 0.59);
          background-position: right center;
        }
      }
    }
  }
}
