#page-home {
  .mobile-ctas {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    z-index: 45;
    display: flex;
    justify-content: center;
    align-items: center;

    /** Desktop. */
    @media (min-width: 1200px) {
      display: none;
    }

    .cta {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      button {
        background-color: #e37e21;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        height: 60px;
        padding-top: 4px;
        letter-spacing: 2px;
        box-shadow: 0px 15px 22px 0px rgba(19, 27, 46, 0.44);
        transition: letter-spacing 350ms ease, box-shadow 350ms ease,
          background-position 750ms ease;

        &:hover {
          box-shadow: 0px 8px 12px 0px rgba(19, 27, 46, 0.59);
          background-position: right center;
        }
      }
    }
  }

  .section-hero {
    position: relative;
    width: 100%;
    background-color: #f5edec;
    z-index: 3;

    @media (max-width: 768px) {
      min-height: unset;
      max-height: unset;
      height: calc(100vh - 60px);
    }

    .tag {
      position: absolute;
      left: 45%;
      transform: translateX(-50%) scale(0.8);
      transform-origin: bottom center;
      bottom: 0;
      z-index: 4;

      @media (max-width: 1500px) {
        transform: translateX(-50%) scale(0.68);
      }

      @media (max-width: 768px) {
        transform: translateX(-50%) scale(0.5);
      }
    }

    .whatsapp {
      position: fixed;
      top: 43px;
      right: 45px;
      z-index: 45;

      @media (max-width: 768px) {
        top: 30px;
        right: 25px;
      }

      a {
        background-image: linear-gradient(
          -61deg,
          #56aa00 0%,
          #7ef800 50%,
          #56aa00 100%
        );
        background-size: 200% 100%;
        background-position: left center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1px 1px 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        z-index: 2;
        transition: box-shadow 350ms ease, background-position 750ms ease;
        box-shadow: 0 0 10px 6px rgba(86, 170, 0, 0.3);

        svg {
          transform-origin: 50% 50%;
          transform: scale(0.8);
        }
      }

      &:hover {
        a {
          &:hover {
            box-shadow: 0 0 14px 12px rgba(86, 170, 0, 0.4);
            background-position: right center;
          }
        }
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      height: 100%;
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      @media (max-width: 768px) {
        position: relative;
        padding-top: 0;
        flex-direction: column;
        justify-content: flex-start;
      }

      @media screen and (device-aspect-ratio: 40/71) {
        height: 100vh;
        padding-top: 0;
      }

      .left {
        height: 100vh;
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width: 1920px) {
          transform: scale(0.85);
        }

        @media screen and (max-width: 1500px) {
          transform: scale(0.8);
        }

        @media (max-width: 768px) {
          padding: 0;
          height: unset;
        }

        @media screen and (device-aspect-ratio: 40/71) {
          padding: 40px 10px 40px 10px;
          transform: scale(0.75);
          bottom: 20px;
          position: relative;
        }

        .logo {
          width: 100%;
          margin-bottom: 80px;

          @media (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
          }
        }

        .content-wrapper {
          font-weight: 400;
          line-height: 1.4;
          margin-bottom: 20px;

          @media (max-width: 768px) {
            text-align: center;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          }

          h2 {
            font-weight: 700;
            font-size: 42px;
            color: #2f3611;

            @media (max-width: 768px) {
              font-size: 32px;
            }
          }

          img {
            @media (max-width: 768px) {
              width: 130px;
            }
          }
        }

        .infos-wrapper {
          display: flex;
          justify-content: center;
          flex-direction: column;

          @media (max-width: 768px) {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
          }

          img {
            max-width: 500px;

            @media (max-width: 768px) {
              width: 430px;
            }
          }
        }
      }

      .img-mobile {
        display: none;
        width: 100vw;

        @media (max-width: 768px) {
          display: flex;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .right-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;

        @media (max-width: 768px) {
          margin-top: 20px;
        }

        .right {
          width: 380px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          @media screen and (max-width: 1366px) {
            transform: scale(0.9);
          }

          @media (max-width: 768px) {
            display: none;
          }

          &-title {
            background-color: #2b310d;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            height: 70px;
            padding: 0 10px;
            width: 100%;
            margin-bottom: -1px;

            h3 {
              font-size: 24px;
              letter-spacing: 1px;
              text-transform: uppercase;
              line-height: 1.4;
              font-weight: 300;
              color: white;
              letter-spacing: 2px;
            }
          }

          &-form {
            position: relative;
            background-color: #394114;
            width: 100%;
            padding: 35px;

            .field-group {
              &:not(:last-child) {
                margin-bottom: 25px;
              }

              .label {
                color: #f08534;
                font-weight: 300;
                font-size: 16px;
                margin-bottom: 20px;
              }

              .field {
                background-color: transparent;
                color: white;
                font-size: 16px;
                font-weight: 500;
                padding-bottom: 10px;
                font-family: "Arial", sans-serif;
                border: none;
                border-bottom: 1px solid #888d72;
                width: 100%;
                display: block;
                outline: none;
                transition-property: border-color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
                margin-bottom: 20px;

                &::placeholder {
                  color: rgba(255, 255, 255, 0.5);
                  opacity: 1;
                  transition: opacity 450ms;
                }

                &:focus {
                  &::placeholder {
                    opacity: 0;
                  }
                }
              }

              &.invalid .field {
                border-color: red;
              }
            }

            .form-submit {
              position: relative;
              width: 100%;
              margin-top: 40px;

              button {
                background-color: #ff7f08;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                position: relative;
                font-size: 22px;
                font-weight: 300;
                border: 3px solid #ff7f08;
                letter-spacing: 2px;
                width: 100%;
                height: 58px;
                padding-top: 4px;
                box-shadow: 0px 6px 36px 1px rgba(240, 133, 52, 0.53);
                text-transform: uppercase;
                transition: letter-spacing 350ms ease,
                  background-color 350ms ease, box-shadow 350ms ease;

                &:hover {
                  letter-spacing: 1px;
                  background-color: white;
                  box-shadow: 0px 4px 22px 1px rgba(240, 133, 52, 0.46);
                  color: #ff7f08;
                }
              }
            }
          }
        }
      }
    }
  }

  .section-image {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0;
    height: 100vh;
    min-height: 768px;

    @media (max-width: 768px) {
      height: 220px;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      height: 100%;

      @media (max-width: 768px) {
        order: 1;
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      position: relative;
      flex-direction: column;
    }

    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 40px;

      .title {
        font-size: 38px;
        color: rgb(47, 54, 17);
        font-weight: bold;
        text-align: center;
        margin-bottom: 40px;

        @media (max-width: 768px) {
          font-size: 32px;
        }
      }

      .description {
        font-size: 16px;
        color: rgb(47, 54, 17);
        line-height: 1.4;
        text-align: center;
        padding: 0 200px;
        
        @media (max-width: 768px) {
          padding: 0;
        }
      }
    }
  }

  .section-video {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 70px 0 40px 0;

    @media (max-width: 880px) {
      display: flex;
      min-height: unset;
      max-height: unset;
      flex-direction: column;
      padding: 30px 0 0 0;
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      height: 100%;
    }

    .flex-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      position: relative;
      flex-direction: column;
    }

    .address {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 80px;
      font-style: normal;
    }

    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 60px;

      .title-1 {
        font-size: 18px;
        color: #53545a;
        font-weight: 600;
        letter-spacing: 2px;
        margin-bottom: 10px;
        text-transform: uppercase;

        @media (max-width: 768px) {
          text-align: center;
        }
      }

      .title-2 {
        font-size: 38px;
        color: #53545a;
        font-weight: bold;
        text-transform: uppercase;

        @media (max-width: 768px) {
          text-align: center;
          font-size: 28px;
        }
      }
    }

    .video {
      position: relative;
      background-color: black;
      height: 450px;
      aspect-ratio: 16 / 9;
      margin: 30px auto;
      padding: 0;
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.45);

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 100%;
        height: 230px;
      }

      &-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
      }

      &-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0;
          background-color: black;
          z-index: 1;
          opacity: 0.3;
        }

        img {
          position: relative;
          width: 100%;
          height: 100%;
          filter: blur(1px);
          object-fit: cover;
          opacity: 0.85;
        }
      }

      &-icon {
        background-image: linear-gradient(to bottom, #ffffff, #c5ccc0);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        padding: 0 0 0 8px;
        z-index: 2;
        transform-origin: 0% 0%;
        transition: transform time(fast) ease(fade);

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 60px;
          height: 60px;
        }

        svg {
          transform-origin: 50% 50%;
          transition: transform time(fast) ease(fade);

          /** Mobile. */
          @media (max-width: 1023px) {
            transform: scale(0.7);
          }
        }
      }

      &:hover .video-icon {
        transform: scale(0.7) translate(-50%, -50%);

        svg {
          transform: scale(1.1);

          /** Mobile. */
          @media (max-width: 1023px) {
            transform: scale(0.7);
          }
        }
      }
    }
  }

  .section-mapa-numeros-title {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 40px;

    &:before {
      content: "";
      position: absolute;
      width: 60vw;
      height: 18px;
      background-color: #ff7f08;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      height: 100%;

      @media (max-width: 768px) {
        order: 1;
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      position: relative;
      flex-direction: column;
    }

    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 40px;

      .title {
        font-size: 30px;
        color: rgb(47, 54, 17);
        text-align: center;
        line-height: 1.4;
        margin-bottom: 40px;
        font-weight: bold;

        @media (max-width: 768px) {
          br {
            display: none;
          }
        }
      }
    }
  }

  .section-mapa-numeros {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0;
    height: 100vh;
    min-height: 768px;

    @media (max-width: 768px) {
      height: unset;
      min-height: unset;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      height: 100%;
      user-select: none;
      pointer-events: none;

      @media (max-width: 768px) {
        order: 1;
      }

      .content-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .mapa-numeros-mobile {
          width: 100vw;
        }
      }

      .legenda {
        position: absolute;
        bottom: 20px;
        left: 5vw;
        max-width: 500px;

        @media (max-width: 768px) {
          position: relative;
          bottom: 0;
          left: 0;
          margin-top: 20px;
          width: 96vw;
        }
      }
    }
  }

  .section-slider {
    position: relative;
    width: 100%;
    padding-bottom: 30px;
    margin-top: 80px;

    @media (max-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      height: 400px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: #fff;
      z-index: 1;
    }

    .container {
      max-width: 100%;
      overflow: hidden;
    }

    .title-wrapper {
      margin-bottom: 70px;
      padding-top: 40px;
      width: 100%;
      position: relative;
      text-align: center;

      @media (max-width: 768px) {
        margin-bottom: 0;
        padding-bottom: 80px;
        position: relative;
        z-index: 2;

        br {
          display: none;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 18px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 1px;
        background-color: #978075;

        @media (max-width: 768px) {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .subtitle {
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-size: 18px;
        color: rgb(151, 128, 117);
        line-height: 1.27;
        margin-bottom: 10px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      .title {
        font-size: 46px;
        font-family: "Prompt";
        color: rgb(167, 119, 106);
        font-weight: bold;

        @media (max-width: 768px) {
          font-size: 38px;
        }
      }
    }

    .text {
      color: #244651;
      text-align: center;
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: 80px;

      @media (max-width: 768px) {
        padding: 0 15px;

        br {
          display: none;
        }
      }
    }

    .plantas {
      position: relative;

      @media (max-width: 768px) {
        margin-top: 0;
      }

      .item {
        position: relative;
        margin: 0 auto;
        width: 800px;
        height: 450px;

        @media (max-width: 768px) {
          width: 100%;
          margin: 0 auto;
          padding: 0;
          height: unset;
        }

        .content {
          width: 100%;
          max-width: 610px;
          margin: 0 auto;
        }

        &__row-zoom {
          display: flex;
          justify-content: flex-end;
          position: relative;
          top: 0;
          right: 120px;
          z-index: 2;

          @media (max-width: 768px) {
            right: 0;
            top: 0;
          }

          a {
            font-size: 14px;
            color: black;
            line-height: 1.2;
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
              transform: scale(0.7);
            }

            span {
              margin-right: 10px;
              transition: transform 350ms ease;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }

        &__row-image {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          height: 94%;
          max-width: 770px;
          box-shadow: 0px 6px 8px 0px rgba(89, 89, 89, 0.45);

          @media (max-width: 768px) {
            height: 90%;
            box-shadow: unset;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__caption {
          width: 100%;
          height: 6%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: absolute;
          padding: 10px 12px;
          right: 20px;
          bottom: 34px;

          @media (max-width: 768px) {
            padding: 0;
            height: 10%;
          }

          span {
            font-size: 14px;
            padding-top: 6px;
            color: white;
            font-weight: 400;
            letter-spacing: 1px;
            right: 0;
            text-align: center;
            text-transform: uppercase;
            position: relative;
            text-shadow: 0px 0px 13px #000000;

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
      }

      .owl-dots {
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        display: none;

        @media (max-width: 768px) {
          display: flex;
          margin-top: 20px;
        }

        .owl-dot {
          width: 10px;
          height: 10px;
          background-color: #fceadc;
          border-radius: 50%;
          margin: 0 3px;
          transition: background-color 350ms ease;

          &.active {
            background-color: #f3954f;
          }
        }
      }

      .image-plantas__navigation {
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        bottom: 50%;
        transform: translateY(50%);
        z-index: 2;

        @media (max-width: 768px) {
          justify-content: center;
          bottom: -90px;
          height: 60px;
          top: unset;
          transform: unset;
        }

        .arrow {
          opacity: 1;
          transition: 250ms;

          @media (max-width: 768px) {
            transform: scale(0.4);
          }

          &:hover {
            opacity: 0.6;
          }

          &__prev {
            position: absolute;
            left: 25%;
            transform: translate(-30%) scale(0.7);

            @media screen and (max-width: 1920px) {
              left: 20%;
            }

            @media screen and (max-width: 1500px) {
              left: 10%;
            }
          }

          &__next {
            position: absolute;
            right: 25%;
            transform: translate(30%) scale(0.7);

            @media screen and (max-width: 1920px) {
              right: 20%;
            }

            @media screen and (max-width: 1500px) {
              right: 10%;
            }
          }
        }
      }
    }
  }

  .section-diferenciais {
    position: relative;
    width: 100%;
    padding-bottom: 80px;
    overflow: hidden;

    @media (max-width: 768px) {
      top: 0;
      padding-bottom: 0;
    }

    .flex-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      padding-bottom: 30px;

      @media (max-width: 768px) {
        bottom: unset;
        padding: 40px 0;
        text-align: center;
      }
    }

    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 20px;

      .title {
        font-size: 44px;
        font-weight: 700;
        font-style: normal;
        color: #2f3611;
      }
    }

    .grid-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      &:last-child {
        padding-left: 200px;

        @media (max-width: 768px) {
          padding-left: 0;
        }
      }

      .grid-title {
        color: #394113;
        font-size: 14px;

        @media (max-width: 768px) {
          margin-bottom: 4px;
          text-align: left;
          font-size: 12px;
        }
      }
    }

    .grid {
      display: grid;
      gap: 25px;
      margin-top: 2px;
      grid-template-columns: repeat(6, 1fr);

      @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        margin-top: 0;
        gap: 30px 6px;
      }

      .grid-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 180px;
        height: 135px;
        border: 2px solid #ec8336;
        padding: 30px 6px 6px 6px;
        position: relative;

        @media (max-width: 768px) {
          width: 120px;
          height: 90px;
          padding: 20px 0 0 0;
        }

        &.blank {
          border: none;

          @media (max-width: 768px) {
            display: none;
          }
        }

        .subtitle {
          color: #394113;
          font-weight: bold;
          font-size: 14px;
          position: absolute;
          left: -2px;
          top: -16px;

          @media (max-width: 768px) {
            top: -20px;
            font-size: 12px;
          }
        }

        .icon {
          max-width: 55px;
          max-height: 40px;
          margin-bottom: 10px;

          @media (max-width: 768px) {
            max-width: 28px;
            max-height: 28px;
            margin-bottom: 2px;
          }
        }

        &__title {
          text-align: center;
          position: relative;
          padding-bottom: 6px;
          width: 100%;

          .title {
            font-size: 14px;
            font-weight: 500;
            color: #354e5c;
            font-style: normal;
            font-family: "Trebuchet MS", sans-serif;

            @media (max-width: 768px) {
              font-size: 11px;
              font-weight: bold;
              line-height: 0;
            }
          }
        }
      }
    }
  }

  .section-mapa-title {
    position: relative;
    width: 100%;
    padding-bottom: 40px;

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      height: 100%;

      @media (max-width: 768px) {
        order: 1;
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      position: relative;
      flex-direction: column;
    }

    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 40px;

      .title {
        font-size: 38px;
        color: rgb(47, 54, 17);
        text-align: center;
        line-height: 1.4;
      }
    }
  }

  .section-mapa {
    position: relative;
    width: 100%;
    padding: 0;
    height: 130vh;
    min-height: 768px;

    @media (max-width: 768px) {
      height: 280px;
      min-height: unset;
    }

    .grafismo-left {
      position: absolute;
      top: -170px;
      left: 0;
      z-index: 2;
      width: 160px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .grafismo-right {
      position: absolute;
      top: -80px;
      right: 0;
      z-index: 2;
      width: 160px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .grafismo-left-bottom {
      position: absolute;
      bottom: -190px;
      left: 0;
      z-index: 2;
      width: 160px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      height: 100%;

      @media (max-width: 768px) {
        order: 1;
      }
    }
  }

  .section-form {
    width: 100%;
    padding: 140px 0;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      padding: 20px 0 90px 0;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .content-wrapper {
      display: flex;
      justify-content: space-around;
      position: relative;
      align-items: center;
      height: 100%;
      z-index: 2;

      @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 60px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
    }

    .form-title-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;

      @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
      }

      .title {
        font-size: 42px;
        line-height: 1.1;
        color: #2f3611;

        @media (max-width: 768px) {
          font-size: 34px;
        }
      }
    }

    .description-wrapper {
      margin-top: 20px;

      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .description {
        font-size: 16px;
        color: #2f3611;
        line-height: 1.6;
      }
    }

    .cta {
      position: relative;
      width: 360px;
      margin: 0;
      padding: 0;
      box-shadow: 0px 17px 49px 0px rgba(0, 0, 0, 0.41);

      @media (max-width: 768px) {
        width: 85vw;
        margin-top: 40px;
      }

      .form {
        background-color: #394114;
        width: 100%;
        margin: 0;
        padding: 25px 25px 35px;

        .field-group {
          position: relative;
          width: 100%;
          margin: 0;
          padding: 0;
          border-bottom: 1px solid #707070;
          transition: border-color time(fast) ease(fade);
          margin: 20px 0 40px 0;

          label {
            font-size: 15px;
            color: #f08534;
            font-weight: 600;
            position: relative;
            bottom: 14px;
          }

          .placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-size: 15px;
            font-weight: 400;
            position: absolute;
            bottom: 8px;
            left: 0;
            pointer-events: none;
            transition: opacity time(default) ease(fade);
          }

          .field {
            background-color: transparent;
            color: white;
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            padding: 0 0 8px;
            border: none;
            width: 100%;
            display: block;
          }

          &.invalid {
            border-color: red;
          }
          &.focus,
          &.not-empty {
            .placeholder {
              opacity: 0;
            }
          }
        }

        .form-submit {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          margin: 10px 0 0;
          padding: 0;

          button {
            background-color: #ff7f08;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            font-size: 20px;
            font-weight: 300;
            border: 3px solid #ff7f08;
            letter-spacing: 2px;
            width: 100%;
            height: 58px;
            padding-top: 4px;
            box-shadow: 0px 6px 36px 1px rgba(240, 133, 52, 0.53);
            text-transform: uppercase;
            transition: letter-spacing 350ms ease, background-color 350ms ease,
              box-shadow 350ms ease;

            &:hover {
              letter-spacing: 1px;
              background-color: white;
              box-shadow: 0px 4px 22px 1px rgba(240, 133, 52, 0.46);
              color: #ff7f08;
            }
          }
        }
      }
    }
  }

  .section-instagram {
    width: 100%;
    padding-top: 100px;
    position: relative;
    background-color: #394114;
    z-index: 2;

    @media (max-width: 768px) {
      padding-top: 0;
    }

    .grafismo-footer-left {
      position: absolute;
      bottom: -170px;
      left: 0;
      z-index: 2;
      width: 160px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .grafismo-footer-right {
      position: absolute;
      top: -80px;
      right: 0;
      z-index: 2;
      width: 140px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 60vw;
      height: 18px;
      background-color: #ff7f08;
      left: 50%;
      transform: translateX(-50%);
      top: 0;

      @media (max-width: 768px) {
        width: 90vw;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 60vw;
      height: 1px;
      background-color: #ff7f08;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;

      @media (max-width: 768px) {
        width: 90vw;
      }
    }

    .content-wrapper {
      display: flex;
      justify-content: space-evenly;
      position: relative;
      align-items: center;
      height: 100%;
      z-index: 2;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
    }

    .left {
      .title-wrapper {
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;

        @media (max-width: 768px) {
          padding-top: 40px;
        }

        .subtitle {
          font-size: 60px;
          font-weight: 300;
          color: #244651;
          margin-top: -10px;
          margin-bottom: -20px;

          @media (max-width: 768px) {
            text-align: center;
            font-size: 50px;
            margin-bottom: 10px;
          }
        }

        .title {
          margin: 10px 0;
          font-size: 38px;
          font-weight: 300;
          color: white;

          @media (max-width: 768px) {
            font-size: 32px;
            text-align: center;
          }
        }
      }

      .instagram-button {
        width: 180px;
        height: 50px;
        background-color: #ff7f08;
        position: relative;
        text-transform: uppercase;
        color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border: 2px solid #ff7f08;
        margin-top: 10px;
        font-size: 18px;
        box-shadow: 0px 6px 36px 1px rgba(240, 133, 52, 0.53);
        transition: box-shadow 350ms ease;

        &:hover {
          box-shadow: 0px 4px 22px 1px rgba(240, 133, 52, 0.46);
        }

        svg {
          transform: scale(0.6);
        }

        .instagram-link {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0;
        }
      }
    }

    .right {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
        position: relative;
        margin-top: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
